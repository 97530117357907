import { useState, useRef, useEffect } from "react";
import "./SideMenu.scss";
import logo_green from "../../img/logo_green.svg";
import { motion, AnimatePresence, useCycle } from "framer-motion";
import OutsideAlerter from "./OutSideAlerter";
import SideMenuExtension from "./SideMenuExtension";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleLeftDrawer } from "../../actions/actionCreators";
import {
  closeSideMenuExtension,
  getCategoriesUnderGender,
  getGendersUnderfabric,
  getGenders,
  getCollections,
} from "../../actions/left-menu-actions/actions";
import { Button, Skeleton } from "antd";
import axiosInstance from "../../config/axios-instance";
import { PlusOutlined } from "@ant-design/icons";
export interface ISideMenuProps {}

const MenuItemGender = ({
  item,
  setGender,
  setShowMenuExt,
  menuchoice,
}: any) => {
  const dispatch = useDispatch();
  return (
    <p
      className="menu_element"
      onClick={() => {
        dispatch(getCategoriesUnderGender(item.id, menuchoice));
        setGender(item);
        setShowMenuExt(true);
      }}
    >
      {item.name}
    </p>
  );
};

export default function SideMenu(props: ISideMenuProps) {
  const [gender, setGender] = useState("");
  const dispatch = useDispatch();
  const [menuchoice, setMenuChoise] = useState("denim");
  const [showMenuExt, setShowMenuExt] = useState<boolean | string>(false);
  const ref = useRef(null);
  const { user, isAdmin } = useSelector((state: any) => state.auth);

  const [isOpened, setIsOpened] = useState(false);
  function toggle() {
    setIsOpened((wasOpened) => !wasOpened);
  }

  const variants = {
    enter: { x: -362 },
    center: { x: 0 },
    exit: { x: -362 },
  };

  const leftMenu = useSelector((state: any) => state.leftMenu);
  const [subElements, setsubElements] = useState();
  /* 
  const getCollections = async () => {
    let token = localStorage.getItem("token");

    await axiosInstance({
      method: "get",
      url: `/collections`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((data) => {
      if (data && data.data) {
        setsubElements(
          data.data
            .sort((a: any, b: any) => (a.sort_order > b.sort_order ? 1 : -1))
            .filter((k: any) => !k.collection_id)[0]?.id
        );
      }
    });
  }; */
  useEffect(() => {
    /*     getCollections();
     */
    if (!leftMenu[menuchoice + "Genders"]) {
      dispatch(getGendersUnderfabric(menuchoice));
      dispatch(getGenders());

      /*       dispatch(getCollections());
       */
    }
  }, [menuchoice]);

  useEffect(() => {
    /*     console.log(subElements);
     */ setMenuChoise(user && user.fabric_type === "knit" ? "knit" : "denim");
  }, []);

  return (
    <OutsideAlerter>
      <>
        <AnimatePresence initial={true} exitBeforeEnter={true}>
          <motion.div
            className="side_menu"
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ type: "tween" }}
          >
            <Link to="/home" onClick={() => dispatch(handleLeftDrawer())}>
              <img src={logo_green} alt="" className="logo_side_menu" />
            </Link>

            <div className="denim_knit_menu">
              <p
                className={
                  menuchoice === "denim"
                    ? "denim_knit_text selected_menu"
                    : "denim_knit_text"
                }
                onClick={() => {
                  setMenuChoise("denim");

                  dispatch(closeSideMenuExtension());
                  setShowMenuExt(false);
                }}
              >
                DENIM
              </p>
              <div className="denim_knit_seperator"></div>
              <p
                className={
                  menuchoice === "knit"
                    ? "denim_knit_text selected_menu"
                    : "denim_knit_text"
                }
                onClick={() => {
                  setMenuChoise("knit");
                  dispatch(closeSideMenuExtension());
                  setShowMenuExt(false);
                }}
              >
                KNIT
              </p>
              <div className="denim_knit_seperator"></div>

              <p
                className={"denim_knit_text"}
                onClick={() => {
                  dispatch(handleLeftDrawer());
                }}
              >
                <Link to="/products/mix" className={"mix_product"}>
                  MIX
                </Link>
              </p>
            </div>
            <div className="menu_elements">
              <p
                className="menu_element menu_element_new"
                onClick={() => {
                  setShowMenuExt("New");
                  dispatch(handleLeftDrawer());
                }}
              >
                {menuchoice === "denim" ? (
                  <Link
                    to="/new/denim"
                    onClick={() => (window.location.href = "/new/denim")}
                  >
                    New
                  </Link>
                ) : (
                  <Link
                    to="/new/knit"
                    onClick={() => (window.location.href = "/new/knit")}
                  >
                    New
                  </Link>
                )}
              </p>
              {leftMenu.isLoadingGenders || !leftMenu[menuchoice + "Genders"]
                ? Array.from({ length: 1 }, (_, index) => index + 1).map(
                    (_, i: number) => <Skeleton active={true} key={i} />
                  )
                : leftMenu[menuchoice + "Genders"] && (
                    <>
                      {/*     <MenuItemGender
                        item={leftMenu[menuchoice + "Genders"][1]}
                        setGender={setGender}
                        setShowMenuExt={setShowMenuExt}
                        menuchoice={menuchoice}
                      />
                      <MenuItemGender
                        item={leftMenu[menuchoice + "Genders"][0]}
                        setGender={setGender}
                        setShowMenuExt={setShowMenuExt}
                        menuchoice={menuchoice}
                      />
                      <MenuItemGender
                        item={leftMenu[menuchoice + "Genders"][2]}
                        setGender={setGender}
                        setShowMenuExt={setShowMenuExt}
                        menuchoice={menuchoice}
                      /> */}
                      {menuchoice === "denim" ? (
                        <>
                          <p
                            className="menu_element menu_element_kids"
                            onClick={() => {
                              dispatch(handleLeftDrawer());
                            }}
                          >
                            <Link
                              to="/product/denim/Women/2/JEANS/1"
                              onClick={() =>
                                (window.location.href =
                                  "/product/denim/Women/2/JEANS/1")
                              }
                            >
                              {leftMenu[menuchoice + "Genders"][1]?.name}
                            </Link>
                          </p>
                          <p
                            className="menu_element menu_element_kids"
                            onClick={() => {
                              dispatch(handleLeftDrawer());
                            }}
                          >
                            <Link
                              to="/product/denim/Men/1/JEANS/1"
                              onClick={() =>
                                (window.location.href =
                                  "/product/denim/Men/1/JEANS/1")
                              }
                            >
                              {leftMenu[menuchoice + "Genders"][0]?.name}
                            </Link>
                          </p>
                          <p
                            className="menu_element menu_element_kids"
                            onClick={() => {
                              dispatch(handleLeftDrawer());
                            }}
                          >
                            <Link
                              to="/product/denim/Unisex/3/JEANS/1"
                              onClick={() =>
                                (window.location.href =
                                  "/product/denim/Unisex/3/JEANS/1")
                              }
                            >
                              {leftMenu[menuchoice + "Genders"][2]?.name}
                            </Link>
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            className="menu_element menu_element_kids"
                            onClick={() => {
                              dispatch(handleLeftDrawer());
                            }}
                          >
                            <Link
                              to="/product/Knit/Women/2/TOPS%20&%20T-SHIRTS/12"
                              onClick={() =>
                                (window.location.href =
                                  "/product/Knit/Women/2/TOPS%20&%20T-SHIRTS/12")
                              }
                            >
                              {leftMenu[menuchoice + "Genders"][1].name}
                            </Link>
                          </p>
                          <p
                            className="menu_element menu_element_kids"
                            onClick={() => {
                              dispatch(handleLeftDrawer());
                            }}
                          >
                            <Link
                              to="/product/knit/Men/1/Tops%20&%20T-shirt/12"
                              onClick={() =>
                                (window.location.href =
                                  "/product/knit/Men/1/Tops%20&%20T-shirt/12")
                              }
                            >
                              {leftMenu[menuchoice + "Genders"][0].name}
                            </Link>
                          </p>
                          <p
                            className="menu_element menu_element_kids"
                            onClick={() => {
                              dispatch(handleLeftDrawer());
                            }}
                          >
                            <Link
                              to="/product/knit/Unisex/3/Jeans/1"
                              onClick={() =>
                                (window.location.href =
                                  "/product/knit/Unisex/3/Jeans/1")
                              }
                            >
                              {leftMenu[menuchoice + "Genders"][2].name}
                            </Link>
                          </p>
                        </>
                      )}
                    </>
                  )}
              {menuchoice === "denim" && (
                <>
                  {/*         <p
                    className="menu_element"
                    onClick={() => {
                      dispatch(getCategoriesUnderGender(4, menuchoice));
                      setGender(leftMenu.allGenders.data[3]);
                      setShowMenuExt(true);
                    }}
                  >
                    Kids
                  </p>
 */}
                  <p className="menu_element" onClick={toggle}>
                    Kids
                  </p>

                  {isOpened && (
                    <>
                      <p
                        className="menu_element menu_element_kids"
                        style={{ marginLeft: "25px" }}
                        onClick={() => {
                          //setShowMenuExt("New");
                          dispatch(handleLeftDrawer());
                        }}
                      >
                        <Link
                          to="/product/denim/Boys/5/JEANS/1"
                          onClick={() =>
                            (window.location.href =
                              "/product/denim/Boys/5/JEANS/1")
                          }
                        >
                          Boys
                        </Link>
                      </p>
                      <p
                        className="menu_element menu_element_kids"
                        style={{ marginLeft: "25px" }}
                        onClick={() => {
                          //setShowMenuExt("New");
                          dispatch(handleLeftDrawer());
                        }}
                      >
                        <Link
                          to="/product/denim/Girls/6/JEANS/1"
                          onClick={() =>
                            (window.location.href =
                              "/product/denim/Girls/6/JEANS/1")
                          }
                        >
                          Girls
                        </Link>
                      </p>
                    </>
                  )}
                </>
              )}
              {/*      <p
                className="menu_element menu_element_collection"
                onClick={() => {
                  setShowMenuExt(true);
                  setGender("collection");
                }}
              >
                Collection
              </p> */}
              <p
                className="menu_element menu_element_new"
                style={{ marginTop: "6px" }}
                onClick={() => {
                  //setShowMenuExt("New");
                  dispatch(handleLeftDrawer());
                }}
              >
                {menuchoice === "denim" ? (
                  <Link
                    to="/collection/25/denim"
                    onClick={() =>
                      (window.location.href = "/collection/25/denim")
                    }
                  >
                    Collection
                  </Link>
                ) : (
                  <Link
                    to="/collection/25/knit"
                    onClick={() =>
                      (window.location.href = "/collection/25/knit")
                    }
                  >
                    Collection
                  </Link>
                )}
              </p>
              {user && user.type == "admin" && (
                <>
                  {" "}
                  <p
                    style={{ position: "absolute", bottom: 40, right: 20 }}
                    onClick={() => {
                      //setShowMenuExt("New");
                      dispatch(handleLeftDrawer());
                    }}
                  >
                    <Link
                      //    href="/add-new-product"
                      to="/list-products"
                      className="add-new-product-button"
                    >
                      Add new product
                    </Link>
                  </p>
                </>
              )}
            </div>
          </motion.div>
        </AnimatePresence>

        {showMenuExt && (
          <SideMenuExtension
            showMenuExt={showMenuExt}
            menuchoice={menuchoice}
            gender={gender}
            //@ts-ignore
            ref={ref}
          />
        )}
      </>
    </OutsideAlerter>
  );
}
